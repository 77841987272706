import {ActionReducerMap} from '@ngrx/store'

import * as fromAssignmentIndicatorStore from './assignment-indicators-store/reducer'
import * as fromAssignmentIndicatorStoreEffects from './assignment-indicators-store/effects'
// import * as fromTimeEntriesStore from './timeentriesStore/reducer'
// import * as fromTimeEntriesStoreEffects from './timeentriesStore/effects'


export interface AppState {
  assignmentIndicatorsStore: fromAssignmentIndicatorStore.State
  // timeEntriesStore: fromTimeEntriesStore.State

}

export const appReducer: ActionReducerMap<AppState> = {
  assignmentIndicatorsStore: fromAssignmentIndicatorStore.reducer,
  //   timeEntriesStore: fromTimeEntriesStore.reducer,

}

export const storeEffects = [
  fromAssignmentIndicatorStoreEffects.Effects,
  // fromTimeEntriesStoreEffects.Effects
]

