{
  "name": "timebox",
  "version": "cv_1.8.1",
  "engines": {
    "node": ">=18.0.0"
  },
  "scripts": {
    "test-single-headless": "ng test --watch=false --browsers=ChromeHeadless --reporters=progress,junit",
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0",
    "build": "ng build",
    "electron:run": "npm run electron:prepare && cd electron && npm start && cd ..",
    "tomcat:prepare": "npm run build && node ./scripts/build-tomcat-war.js",
    "tomcat:prepare-integration": "npm run build-integration && node ./scripts/build-tomcat-war.js",
    "tomcat:prepare-abnahme": "npm run build-abnahme && node ./scripts/build-tomcat-war.js",
    "tomcat:prepare-production": "npm run build-production && node ./scripts/build-tomcat-war.js",
    "electron:prepare": "npm run build && node ./scripts/npm-install-in-electron-directory.js && node ./scripts/copy-angular-dist.js develop",
    "electron:prepare-integration": "npm run build-integration && node ./scripts/npm-install-in-electron-directory.js && node ./scripts/copy-angular-dist.js integration",
    "electron:prepare-abnahme": "npm run build-abnahme && node ./scripts/npm-install-in-electron-directory.js && node ./scripts/copy-angular-dist.js abnahme",
    "electron:prepare-production": "npm run build-production && node ./scripts/npm-install-in-electron-directory.js && node ./scripts/copy-angular-dist.js production",
    "electron:macos-build": "npm run electron:prepare && node scripts/build-electron-app.js macos",
    "electron:macos-build-integration": "npm run electron:prepare-integration && node scripts/build-electron-app.js macos",
    "electron:macos-build-abnahme": "npm run electron:prepare-abnahme && node scripts/build-electron-app.js macos",
    "electron:macos-build-production": "npm run electron:prepare-production && node scripts/build-electron-app.js macos",
    "electron:windows-build": "npm run electron:prepare && node scripts/build-electron-app.js windows",
    "electron:windows-build-integration": "npm run electron:prepare-integration && node scripts/build-electron-app.js windows",
    "electron:windows-build-abnahme": "npm run electron:prepare-abnahme && node scripts/build-electron-app.js windows",
    "electron:windows-build-production": "npm run electron:prepare-production && node scripts/build-electron-app.js windows",
    "test": "ng test",
    "test:debug": "ng test --watch --browsers Chrome",
    "lint.disabled": "ng lint",
    "e2e": "ng e2e",
    "test-playwright": "npx playwright test --ui"
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/schematics": "^17.3.6",
    "@angular/animations": "^17.3.7",
    "@angular/cdk": "^17.3.3",
    "@angular/common": "^17.3.7",
    "@angular/compiler": "^17.3.7",
    "@angular/core": "^17.3.7",
    "@angular/forms": "^17.3.7",
    "@angular/material": "^17.3.3",
    "@angular/platform-browser": "^17.3.7",
    "@angular/platform-browser-dynamic": "^17.3.7",
    "@angular/router": "^17.3.7",
    "@azure/msal-angular": "^3.0.16",
    "@azure/msal-browser": "^3.13.0",
    "@microsoft/applicationinsights-web": "^3.2.0",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "jssha": "^3.3.1",
    "rxjs": "~7.8.1",
    "ts-deepcopy": "^0.1.4",
    "tslib": "^2.6.2",
    "uuid": "^9.0.1",
    "zone.js": "~0.14.5"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.6",
    "@angular/cli": "^17.3.6",
    "@angular/compiler-cli": "^17.3.7",
    "@angular/language-service": "^17.3.7",
    "@playwright/test": "^1.43.1",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/node": "^20.12.8",
    "archiver": "^7.0.1",
    "codelyzer": "^6.0.0",
    "electron-builder": "^24.13.3",
    "electron-notarize": "^1.0.0",
    "fs-extra": "^11.2.0",
    "jasmine-core": "~5.1.2",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "karma-junit-reporter": "^2.0.1",
    "karma-phantomjs-launcher": "^1.0.4",
    "phantomjs-prebuilt": "^2.1.16",
    "playwright": "^1.43.1",
    "protractor": "~7.0.0",
    "ts-node": "~10.9.2",
    "tslint": "~6.1.0",
    "typescript": "~5.4.5"
  }
}
